<template>
	<div class="page">
         <el-dialog
            title="支付记录"
            top="20vh"
            width="85%"
            :visible.sync="is_show_in_Page"
        >
		<!-- 表格 -->
		<div class="table">
			<div class="tbody">
				<div class="tr" v-for="(tord_item,tord_index) in list.data" :key="tord_index">
					<div class="tord_info">
						<div class="item" style="width:180px">
							<div class="top">运单:{{tord_item.truck_tord_num}}</div>
							<div class="bottom">建单:{{tord_item.creat_time_text}}</div>
						</div>
						<div class="item" style="flex: 1;">
							<div class="top">
								发货地:{{tord_item.case_prov}}/{{tord_item.case_city}}/{{tord_item.case_county}}</div>
							<div class="bottom">{{tord_item.case_other_obj.addr_info}}</div>
						</div>
						<div class="item" style="flex: 1;">
							<div class="top">到货地:{{tord_item.aim_prov}}/{{tord_item.aim_city}}/{{tord_item.aim_county}}
							</div>
							<div class="bottom">{{tord_item.aim_other_obj.addr_info}}</div>
						</div>
						<div class="item" style="width:130px">
							<div class="top">货物:{{tord_item.cargo_type}}/{{tord_item.cargo_name}}</div>
							<div class="bottom">{{tord_item.cargo_weight}}吨/{{tord_item.cargo_volume}}方</div>
						</div>
						<div class="item" style="width:130px">
							<div class="top">总运费:{{tord_item.cost_info.freight_total}}元</div>
							<div class="bottom">已支付:{{tord_item.cost_info.payed_total_of_all}}元</div>
						</div>
						<div class="item" style="width:230px">
							<div class="top">货主:{{tord_item.shipper_info.name}}/{{tord_item.shipper_info.tel}}</div>
							<div class="bottom">{{tord_item.shipper_company_info.name}}</div>
						</div>
						<div class="item" style="width:150px">
							<div class="top">收款人:{{tord_item.truck_owner_info.name}}/{{tord_item.truck_owner_info.tel}}
							</div>
							<div class="bottom">司机:{{tord_item.driver_info.name}}/{{tord_item.driver_info.tel}}</div>
						</div>
						<div class="item" style="width:130px">
							<div class="top">车辆:{{tord_item.truck_plate_num}}</div>
						</div>
					</div>
					<div class="payed_list">
						<div class="ptr" v-for="(payed_item,payed_index) in tord_item.payed_list" :key="payed_index">

							<div class="ptd" style="width:300px">
								<div class="top">id:{{payed_item.id}}</div>
								<div>{{payed_item.payed_num}}</div>
								<div>{{payed_item.creat_time_text}}</div>
							</div>

							<div class="ptd" style="width:300px;">
								<div>[支付]:{{payed_item.this_payed_text}}/服务费:{{payed_item.service_charge_text}}</div>
								<div>状态:{{payed_item.pay_status_text}}</div>
							</div>

							<div class="ptd" style="width:200px;">
								<div>[发票]:{{payed_item.this_invoiced}}元</div>
								<div>状态:{{payed_item.invoice_status_text}}</div>
							</div>

							<div class="ptd" style="width:300px" v-if="payed_item.this_settled_of_truck_owner>0">
								<div>
									[收款人收款]:{{payed_item.this_settled_of_truck_owner}}元/类型:{{payed_item.this_pay_type_text}}
								</div>
								<div style="display:flex;" v-if="payed_item.this_settled_of_truck_owner>0">
									<div>状态:{{payed_item.settle_status_of_truck_owner_text}}</div>
									<div style="margin-left:5px">
								
									</div>
								</div>
								<div>
									{{payed_item.truck_owner_bank_list.num==0?'收款人信息为空':(payed_item.truck_owner_bank_list.num!=1?"收款人绑定多张卡":'') }}
								</div>
								<div v-if="payed_item.truck_owner_bank_list.num==1">
									<div>收款人:{{payed_item.truck_owner_bank_list_info.card_user_name}}</div>
									<div>收款银行:{{payed_item.truck_owner_bank_list_info.bank_cname}}</div>
									<div>收款卡号:{{payed_item.truck_owner_bank_list_info.card_num}}</div>
									<div v-if="payed_item.settle_status_of_truck_owner !=3 && payed_item.text_api">
										打款状态:{{payed_item.text_api}}</div>
									<div>流水编号:{{payed_item.api_running_num_truck_owner}}</div>
								</div>
							</div>
							<div class="ptd" style="width:280px" v-if="payed_item.this_settled_of_driver>0">
								<div>[司机收款]:{{payed_item.this_settled_of_driver}}元/类型:{{payed_item.this_pay_type_text}}
								</div>
								<div style="display:flex;" v-if="payed_item.this_settled_of_driver>0">
									<div>状态:{{payed_item.settle_status_of_driver_text}}</div>
									<div style="margin-left:5px">
										<!-- <div v-if="payed_item.settle_status_of_driver==2" class="btn red" @click="settle_refuse(payed_item,'driver')"  style="color:red">驳回</div> -->

										<!-- <div v-if="payed_item.settle_status_of_driver==4" class="btn blue" @click="settle_open(tord_index,payed_index,'driver')" >重启结算</div> -->
									</div>
								</div>
								<div>
									{{payed_item.driver_bank_list.num==0?'收款人信息为空':(payed_item.driver_bank_list.num!=1?"收款人绑定多张卡":'') }}
								</div>
								<div v-if="payed_item.driver_bank_list.num==1">
									<div>收款人:{{payed_item.driver_bank_list_info.card_user_name}}</div>
									<div>收款银行:{{payed_item.driver_bank_list_info.bank_cname}}</div>
									<div>收款卡号:{{payed_item.driver_bank_list_info.card_num}}</div>
									<div v-if="payed_item.settle_status_of_driver !=3 && payed_item.text_api">
										打款状态:{{payed_item.text_api}}</div>
									<div>流水编号:{{payed_item.api_running_num}}</div>
								</div>
							</div>
							<div class="ptd mark" style="margin-left: 180px;">
								<div class="btn">
									{{payed_item.mark?payed_item.mark:'暂无备注'}}
								</div>
								<div style="clear:both"></div>
								<div class="btn">分组编号-司机:{{payed_item.group_num_driver}}</div>
								<div style="clear:both"></div>
								<div class="btn">分组编号-收款人:{{payed_item.group_num_truck_owner}}</div>
								<div style="clear:both"></div>
								<div class="btn">光大打款状态:{{payed_item.out_cash_type_text}}</div>
								<div style="clear:both"></div>
								<div class="btn">{{payed_item.out_cash_num_text}}</div>
							    <div v-if="payed_item.out_cash_type==4">
									<div class="btn" v-if="payed_item.zz_to_jg_status==4">转账到骏功失败{{payed_item.zz_to_jg_num}}</div>
									<div class="btn" v-if="payed_item.fenzhang_status==4">转账到司机失败{{payed_item.fenzhang_num}}</div>
									<div class="btn" v-if="payed_item.huizong_status==4">资金汇总失败{{payed_item.huizong_num}}</div>
									<div class="btn" v-if="payed_item.out_cash_status==5">出款失败{{payed_item.out_cash_num}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="bottom"></div>
			</div>
		</div>
         </el-dialog>
	</div>
</template>

<script>
	export default {
          props: {
            truck_tord_num: String,
            is_show: Number, //是否显示
        },
		components: {
		},
		data() {
			return {
                  //列表是否显示
                is_show_in_Page: false,
				//表格数据
				list: {
					loading: false, //loading特效
					data: [], //列表数据
					total: 0, //列表总记录数
				},
                form:{}
			}
		},
		computed: {},
		created() {
		},
        watch: {
            is_show(new_data) {
            if (new_data) {
                this.is_show_in_Page = true;
                this.get_page_data();
            }
            },
        },
		methods: {

			//获取货源列表
			get_page_data() {
                this.form.truck_tord_num = this.truck_tord_num;
				//加载中...
				if (this.list.loading) {
					// return;
				}

				//置为加载中
				this.list.loading = true;

				//读取数据
				this.$my.net.req({
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'tord_payed_list_by_admin',
						is_get_shipper_info: true,
						is_get_shipper_company_info: true,
						is_get_truck_owner_info: true,
						is_get_driver_info: true,
						is_get_tord_info: true,
						...this.form,
						...this.page
					},
					callback: (data) => {
						//加载完毕
						this.list.loading = false

						//总数
						this.list.total = data.max

						this.total = data.total;

						//运单列表
						let tord_list = {};

						//
						let payed_num_choosed = {};

						//预处理
						for (let item of data.list) {
							//徽商银行打款状态
							if (item.api_text) {
								item.text_api = "打款中"
							}
							if (item.driver_bank_list.num == 1) {
								item.driver_bank_list_info = item.driver_bank_list.list[0]
							}
							if (item.truck_owner_bank_list.num == 1) {
								item.truck_owner_bank_list_info = item.truck_owner_bank_list.list[0]
							}
							//支付时间
							item.creat_time_text = this.$my.other.totime(item.creat_time);
							//本次支付类型(1:现金,2:燃油费,3:过路费)
							switch (item.this_pay_type) {
								case '1':
									item.this_pay_type_text = "现金";
									break;
								case '2':
									item.this_pay_type_text = "燃油费";
									break;
								case '3':
									item.this_pay_type_text = "过路费";
									break;
								case '4':
									item.this_pay_type_text = "垫资支付";
									break;
							}

							//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
							switch (item.pay_status) {
								case '1':
									item.pay_status_text = "未支付";
									break;
								case '2':
									item.pay_status_text = "支付中";
									break;
								case '3':
									item.pay_status_text = `支付成功 ${this.$my.other.totime(item.payed_time)}`;
									break;
								case '4':
									item.pay_status_text = `支付失败 ${this.$my.other.totime(item.payed_time)}`;
									break;
								case '5':
									item.pay_status_text = `支付撤销 ${this.$my.other.totime(item.payed_time)}`;
									break;
							}
							var invoiced_time = ''
							if (item.invoiced_time!=0) {
								// set_time = ''
								invoiced_time = this.$my.other.totime(item.invoiced_time)
							} else {
								invoiced_time =''
							}
							//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
							switch (item.invoice_status) {
								case '1':
									item.invoice_status_text = "未申请";
									break;
								case '2':
									item.invoice_status_text = "申请中";
									break;
								case '3':
									item.invoice_status_text =
										`开票成功 ${invoiced_time}`;
									break;
								case '4':
									item.invoice_status_text =
										`开票失败 ${invoiced_time}`;
									break;
							}
							var set_time = ''
							if (item.settled_time_of_truck_owner!=0) {
								// set_time = ''
								set_time = this.$my.other.totime(item.settled_time_of_truck_owner)
							} else {

							}
							//结算给车队长的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch (item.settle_status_of_truck_owner) {
								case '1':
									item.settle_status_of_truck_owner_text = "未申请";
									break;
								case '2':
									item.settle_status_of_truck_owner_text = "待打款";
									break;
								case '3':
									item.settle_status_of_truck_owner_text = `打款成功 ${set_time}`;
									break;
								case '4':
									item.settle_status_of_truck_owner_text = `打款失败 ${set_time}`;
									break;
							}
							var time = ''
							if (item.settled_time_of_driver>0) {
								// time = ''
								time = this.$my.other.totime(item.settled_time_of_driver)
							} else {}
							//结算给司机的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch (item.settle_status_of_driver) {
								case '1':
									item.settle_status_of_driver_text = "未申请";
									break;
								case '2':
									item.settle_status_of_driver_text = "待打款";
									break;
								case '3':
									item.settle_status_of_driver_text = `打款成功 ${time}`;
									break;
								case '4':
									item.settle_status_of_driver_text = `打款失败 ${time}`;
									break;
							}

							//本次支付
							item.this_payed_text = item.this_payed + "元"

							//计算本次结给承运人的全部金额
							item.this_settled = parseFloat(item.this_settled_of_truck_owner) + parseFloat(item
								.this_settled_of_driver)

							//服务费率模式: 显示服务费 显示结给司机的钱
							if (item.calc_mode == 1) {

								//服务费
								item.service_charge_text = (item.this_payed - item.this_settled).toFixed(2) +
									"元"

								//结给司机的钱
								item.this_settled_text = item.this_settled + "元"

								//固定金额模式
							} else if (item.calc_mode == 2) {

								//服务费
								item.service_charge_text = "--"

								//结给司机的钱
								item.this_settled_text = "--"
							}

							//装货联系人
							if (item.tord_info.case_link_man) {
								item.tord_info.case_link_man_text = item.tord_info.case_link_man;
							} else item.tord_info.case_link_man_text = "无";

							//装货联系电话
							if (item.tord_info.case_link_tel) {
								item.tord_info.case_link_tel_text = item.tord_info.case_link_tel;
							} else item.tord_info.case_link_tel_text = "无";

							//卸货联系人
							if (item.tord_info.aim_link_man) {
								item.tord_info.aim_link_man_text = item.tord_info.aim_link_man;
							} else item.tord_info.aim_link_man_text = "无";

							//卸货联系电话
							if (item.tord_info.aim_link_tel) {
								item.tord_info.aim_link_tel_text = item.tord_info.aim_link_tel;
							} else item.tord_info.aim_link_tel_text = "无";

							/* 装货地其他数据(json) */
							item.tord_info.case_other_obj = JSON.parse(item.tord_info.case_other);

							//装货时间
							if (!item.tord_info.case_other_obj.case_time_start && item.tord_info.case_other_obj
								.case_time_end) { //只有止点

								item.tord_info.case_other_obj.case_date = this.$my.other.todate(item.tord_info
									.case_other_obj.case_time_end) + "以前";

							} else if (item.tord_info.case_other_obj.case_time_start && !item.tord_info
								.case_other_obj.case_time_end) { //只有起点

								item.tord_info.case_other_obj.case_date = this.$my.other.todate(item.tord_info
									.case_other_obj.case_time_start) + "以后";

							} else if (item.tord_info.case_other_obj.case_time_start && item.tord_info
								.case_other_obj.case_time_end) { //都有

								item.tord_info.case_other_obj.case_date = this.$my.other.todate(item.tord_info
									.case_other_obj.case_time_start) + " ~ " + this.$my.other.todate(item
									.tord_info.case_other_obj.case_time_end);

							} else if (!item.tord_info.case_other_obj.case_time_start && !item.tord_info
								.case_other_obj.case_time_end) { //都没有

								item.tord_info.case_other_obj.case_date = "未设置";
							}

							/* 卸货地其他数据(json) */
							item.tord_info.aim_other_obj = JSON.parse(item.tord_info.aim_other);

							//卸货时间
							if (!item.tord_info.aim_other_obj.aim_time_start && item.tord_info.aim_other_obj
								.aim_time_end) { //只有止点

								item.tord_info.aim_other_obj.aim_date = this.$my.other.todate(item.tord_info
									.aim_other_obj.aim_time_end) + "以前";

							} else if (item.tord_info.aim_other_obj.aim_time_start && !item.tord_info
								.aim_other_obj.aim_time_end) { //只有起点

								item.tord_info.aim_other_obj.aim_date = this.$my.other.todate(item.tord_info
									.aim_other_obj.aim_time_start) + "以后";

							} else if (item.tord_info.aim_other_obj.aim_time_start && item.tord_info
								.aim_other_obj.aim_time_end) { //都有

								item.tord_info.aim_other_obj.aim_date = this.$my.other.todate(item.tord_info
									.aim_other_obj.aim_time_start) + " ~ " + this.$my.other.todate(item
									.tord_info.aim_other_obj.aim_time_end);

							} else if (!item.tord_info.aim_other_obj.aim_time_start && !item.tord_info
								.aim_other_obj.aim_time_end) { //都没有

								item.tord_info.aim_other_obj.aim_date = "未设置";
							}

							//运单状态
							switch (item.tord_info.status) {
								case '1':
									item.tord_info.status_text = "待发车";
									break;
								case '2':
									item.tord_info.status_text = "运输中";
									break;
								case '3':
									item.tord_info.status_text = "已到货";
									break;
							}

							//结算方式
							switch (item.tord_info.settlement_type) {
								case '1':
									item.tord_info.settlement_type_text = "按吨数";
									break;
								case '2':
									item.tord_info.settlement_type_text = "按方数";
									break;
								case '3':
									item.tord_info.settlement_type_text = "按趟";
									break;
							}

							//是否回程
							switch (item.tord_info.is_need_return) {
								case '1':
									item.tord_info.is_need_return_text = "需要回程";
									break;
								case '2':
									item.tord_info.is_need_return_text = "不需要回程";
									break;
							}
							//光大状态
							if(item.out_cash_type == 4){
								item.out_cash_type_text = '光大出款'
							}
							if(item.out_cash_num){
								item.out_cash_num_text = '已发起打款'
							}else{
								item.out_cash_num_text = '光大分账中'
							}
							//汇总运单数据
							if (!tord_list[item.tord_info.id]) {
								tord_list[item.tord_info.id] = JSON.parse(JSON.stringify(item.tord_info))
								tord_list[item.tord_info.id].cost_info = JSON.parse(JSON.stringify(item
									.cost_info))
								tord_list[item.tord_info.id].driver_info = JSON.parse(JSON.stringify(item
									.driver_info))
								tord_list[item.tord_info.id].shipper_info = JSON.parse(JSON.stringify(item
									.shipper_info))
								tord_list[item.tord_info.id].shipper_company_info = JSON.parse(JSON.stringify(
									item.shipper_company_info))
								tord_list[item.tord_info.id].truck_owner_info = JSON.parse(JSON.stringify(item
									.truck_owner_info))
								tord_list[item.tord_info.id].payed_list = [];
							}
							//缓存运单id
							var tord_id = item.tord_info.id
							tord_list[tord_id].creat_time_text = this.$my.other.totime(item.tord_info
								.creat_time);
							//删除一些数据
							delete(item.tord_info);
							delete(item.cost_info);
							delete(item.driver_info);
							delete(item.shipper_info);
							delete(item.shipper_company_info);
							delete(item.truck_owner_info);
							delete(item.truck_info);
							//置入支付记录数据
							tord_list[tord_id].payed_list.push(JSON.parse(JSON.stringify(item)))

							//置入
							payed_num_choosed[item.payed_num] = {
								id:item.id,
								checked: false,
								payed_num: item.payed_num,
								truck_tord_num: item.truck_tord_num,
								pay_status: item.pay_status,
								invoice_status: item.invoice_status,
								settle_status_of_truck_owner: item.settle_status_of_truck_owner,
								settle_status_of_driver: item.settle_status_of_driver,
								this_settled: item.this_settled,
								this_settled_text: item.this_settled + "元",
								driver_bank_list: item.driver_bank_list,
								truck_owner_bank_list: item.truck_owner_bank_list
							}
						}
						//渲染
						this.list.data = Object.values(tord_list).reverse()

						//缓存支付编号数据
						this.payed_num_choosed = payed_num_choosed;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.table {
		background-color: #fff;
		color: #666;
		text-align: left;
		height: calc(100% - 140px);
		font-size: 12px;
		border-bottom: 1px solid #ccc;

		.thead {
			border-bottom: 1px solid #ccc;
		}

		.tbody {
			height: calc(100% - 41px);
			overflow-y: auto;

			.tr {
				padding: 10px;
				border: 1px solid #eee;
				margin: 10px;

				.tord_info {
					display: flex;
					padding: 4px;
					background-color: #eee;
					justify-content: space-between;

					.item {
						margin: 3px;
					}
				}

				.payed_list {
					margin-top: 6px;

					.ptr {
						display: flex;

						.ptd {
							padding: 3px;
							margin: 3px;

							.btn {
								cursor: pointer;
								display: inline-block;
								padding: 0 2px;
							}

							.blue {
								color: #409EFF;
							}

							.red {
								margin-bottom: 5px;
								color: red;
							}
						}
					}
				}
			}
		}
	}

	#haed {
		text-decoration: none;
		color: rgb(0, 174, 255);
	}

	.page {
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}

	.mark {

		.btn {
			width: 150px;
			overflow: hidden; //超出的文本隐藏
			text-overflow: ellipsis; //溢出用省略号显示
			white-space: nowrap; // 默认不换行；
		}

		.btn:hover {
			text-overflow: inherit;
			overflow: visible;
			white-space: pre-line;
			/*合并空白符序列，但是保留换行符。*/
		}

	}

	.ser_form {
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}

	.el_form_item {
		width: 16.6672%;
	}

	.el_input {
		width: 94%
	}

	.btn_left {
		margin: 0;
	}

	.total_info {
		text-align: right;
		font-size: 15px;
		margin-bottom: 10px;
		margin-right: 1px;
		color: #606266;

		span {
			margin-left: 10px;
		}
	}
</style>